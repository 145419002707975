<template>
  <v-footer height="64px" class="red lighten-0" app>
    <v-layout row justify-center>
      <v-btn
        v-if="menus[0] != null"
        @click="setCurrentMenu(menus[0].menuId)"
        :to="getLink(menus[0])"
      >
        <v-icon>mdi-skip-previous</v-icon>
        {{ menus[0].name }}</v-btn
      >

      <vuetify-audio
        v-if="currentSoundFile != null"
        style="margin-top: -12px; heigth: 64px"
        color="red"
        :autoPlay="false"
        :ended="audioFinish"
        :file="currentSoundFile.soundUrl"
        height="64px"
      >
      </vuetify-audio>

      <v-btn
        v-if="menus[1] != null"
        @click="setCurrentMenu(menus[1].menuId)"
        :to="getLink(menus[1])"
      >
        {{ menus[1].name }} <v-icon>mdi-skip-next</v-icon>
      </v-btn>

      <v-flex xs12 py-3 text-center white--text>
        &copy;2022 —
        <strong>{{ $t("onlineBooks") }}</strong>
      </v-flex>
    </v-layout>
  </v-footer>
</template>

<script>
import AppApi from "@/api/app-api";
import Vue2Filters from "vue2-filters";
import VuetifyAudio from "@/components/vuetifyaudio.vue";
export default {
  components: {
    VuetifyAudio,
  },
  mixins: [Vue2Filters.mixin],
  data: () => ({
    menus: [],
    currentBookId: 0,
    flatMenus: [],
    isAudio: true,
  }),
  methods: {
    audioFinish() {
      console.log("audio is finished");
    },
    setCurrentMenu(menuId) {
      if (menuId > 0) {
        let curMenu = this.find(
          this.$store.getters.getLanguageMenus,
          (menu) => menu.menuId == menuId
        )[0];
        if (curMenu != null) {
          AppApi.setCurrentMenu(curMenu);

          // AppApi.setOtherLanguageLinksLeftMenu(curMenu.menuId);

          if (this.currentBookId != curMenu.bookId) {
            this.currentBookId = curMenu.bookId;
            let litems = AppApi.getLitems();
            this.flatMenus = AppApi.toArray(litems, []);
          }

          const menus = this.flatMenus.filter((item) => {
            return item.menuId == curMenu.menuId;
          });

          let activeMenu = menus[0];
          AppApi.setActiveMenus([activeMenu.id]);
        }
      }
    },
    getLink(item) {
      if (item != null) {
        if (item.sanitizedBookName == item.sanitizedName) {
          return "/" + item.sanitizedBookName + "/" + item.menuId;
        } else {
          return (
            "/" +
            item.sanitizedBookName +
            "/" +
            item.sanitizedName +
            "/" +
            item.menuId
          );
        }
      }
      return "";
    },
  },
  computed: {
    currentBook() {
      return AppApi.getCurrentBook();
    },

    currentMenu() {
      return AppApi.getCurrentMenu();
    },

    currentSoundFile() {
      return AppApi.getCurrentSoundFile();
    },
  },
  mounted() {
    this.unwatch = this.$store.watch(
      (state, getters) => getters.getCurrentMenu,
      (newValue) => {
        if (newValue != null && newValue.menuId) {
          AppApi.SetSoundFilesByMenuId(newValue.menuId);
          AppApi.GetPrevAndNextMenus(newValue.menuId).then((res) => {
            this.menus = res;
          });
        }
      }
    );
  },

  beforeDestroy() {
    this.unwatch();
  },
};
</script>

<style scoped>
.layout {
  flex-wrap: wrap !important;
}
</style>
