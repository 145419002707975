<template>
  <v-col md="10" lg="6" class="mt-10">
    <v-text-field
      clearable
      filled
      rounded
      v-model="searchText"
      :label="$t('placeHolder')"
      :placeholder="$t('search')"
      append-icon="mdi-magnify"
      class="shrink mx-1"
      v-on:keyup.enter="gotoPage"
      @click:append="gotoPage"
    >
    </v-text-field>
  </v-col>
</template>

<script>
export default {
  name: "SearchInput",
  data: () => ({
    searchText: "",
  }),
  methods: {
    gotoPage() {
      this.$router.push("/search/search-by-book?searchtext=" + this.searchText);
    },
  },
};
</script>
