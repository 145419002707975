<template>
  <v-menu top :close-on-content-click="closeOnContentClick">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        dark
        text
        v-bind="attrs"
        v-on="on"
        color="white"
        v-show="items.length > 0"
      >
        <!-- Other Language Links -->
        <v-icon dark> mdi-flag </v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        v-for="(item, index) in orderBy(items, 'menuOrderNo')"
        :key="index"
        :href="getItemLink(item)"
        target="_blank"
      >
        <v-list-item-icon>
          <!-- <country-flag :country="item.languageCode" size="normal" /> -->
          <v-icon>mdi-flag</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          {{ item.name }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import Vue2Filters from "vue2-filters";

export default {
  name: "OtherLanguageMenu",
  mixins: [Vue2Filters.mixin],
  components: {},
  data: () => ({
    items: [],
    closeOnContentClick: true,
    demo: null,
  }),
  methods: {
    getItemLink(item) {
      let link = "/" + item.bookName + "/" + item.latinName + "/" + item.menuId;
      if (item.hashTag) {
        link = link + "#" + item.hashTag;
      }

      return link;
    },
  },
  computed: {},
  mounted() {
    this.unwatch = this.$store.watch(
      (state, getters) => getters.getOtherLanguageLinks,
      (newValue) => {
        if (newValue) {
          // console.log("new Value : ", newValue);
          this.items = [];
          newValue.forEach((element) => {
            this.items.push(element);
          });
        } else {
          this.items = [];
        }
      }
    );
  },
  beforeDestroy() {
    this.unwatch();
  },
};
</script>

<style></style>
