<template>
  <div class="md-typeset mt-10">
    <div class="md-container">
      <div v-if="contenta != null" v-html="contenta"></div>
      <div v-else>
        <span v-html="'<p3><f1>Başlangıç Sayfası</f1></p3>'"> </span>
      </div>
    </div>
  </div>
</template>

<script>
import AppApi from "../api/app-api";
export default {
  name: "StartPage",
  data: () => ({
    contenta: "",
  }),
  metaInfo() {
    return {
      meta: [{ name: "description", content: this.contenta }],
      link: [
        {
          rel: "canonical",
          href: "http://" + window.location.host + this.$route.fullPath,
        },
      ],
    };
  },
  mounted() {
    AppApi.getStartPageContent().then((result) => {
      this.contenta = result;
    });

    AppApi.setActiveMenus([]);
    AppApi.setOpenMenus([0]);
    AppApi.setCurrentBook(null);
    AppApi.setOtherLanguagesMenuNull();
    AppApi.setCurrentMenu(null);
    AppApi.setCurrentLanguage(null);
    let litems = AppApi.getLitems();
    litems[0].children.forEach((item) => {
      item.children = [];
    });
    AppApi.setLitems(litems);
  },
};
</script>
<style scoped>
@import "../assets/css/app.css";
@import "../assets/css/override.css";
</style>
