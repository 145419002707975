<template>
  <component :is="layout">
    <!-- <keep-alive> -->
    <router-view />
    <!-- </keep-alive> -->
  </component>
</template>

<script>
const deafult_layout = "app";
export default {
  data: () => ({}),
  computed: {
    layout() {
      const lay = this.$route.meta.layout
        ? this.$route.meta.layout
        : deafult_layout;
      return lay + "-layout";
    },
  },
};
</script>
