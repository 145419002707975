<template>
  <div>
    <v-navigation-drawer
      id="leftDrawer"
      app
      :right="isRtl"
      fixed
      v-model="drawer"
      width="25rem"
      clipped
    >
      <template v-slot:prepend>
        <v-sheet
          color="red lighten-0 white-text"
          dark
          height="64px"
          class="pa-3 md"
        >
          <span v-if="selectedBook" class="headline">
            {{ selectedBook.name }}
          </span>
          <br />
          <span v-if="selectedMenu" class="title">
            {{ selectedMenu.name }}
          </span>
        </v-sheet>
      </template>

      <v-divider></v-divider>
      <v-treeview
        id="leftMenu"
        :active.sync="active"
        :items="items"
        :open.sync="open"
        color="red"
        item-text="black"
        open-on-click
        activatable
        transition
      >
        <template v-slot:prepend="{ item }">
          <v-icon v-if="item.menuType == 0"> mdi-flag </v-icon>
          <v-icon v-if="item.menuType == 1"> mdi-flag </v-icon>
          <v-icon v-if="item.menuType == 2"> mdi-book-open </v-icon>
          <v-icon v-if="item.menuType == 3 && item.isSection"> mdi-book</v-icon>
          <v-icon v-if="item.menuType == 3 && !item.isSection">
            mdi-bell-circle</v-icon
          >
        </template>

        <template slot="label" slot-scope="{ item }">
          <v-list-item
            v-if="item.menuType == 4"
            @click="setOtherLangugeLinks(item.menuId)"
          >
            <v-list-item-content>
              <a :href="'#' + item.link" style="text-decoration: none">
                {{ item.name }}</a
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-else-if="item.menuType == 1 || item.menuType == 0"
            @click.prevent="updateLeftMenu(item)"
          >
            <v-list-item-content>
              {{ item.name }}
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            else
            v-if="item.menuType == 2"
            @click.prevent="updateLeftMenu(item)"
            :to="'/' + item.sanitizedName + '/' + item.menuId"
          >
            <v-list-item-content>
              {{ item.name }}
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            else
            v-if="item.menuType == 3 && selectedBook"
            @click="updateLeftMenu(item)"
            :to="
              '/' +
              selectedBook.sanitizedName +
              '/' +
              item.sanitizedName +
              '/' +
              item.menuId
            "
          >
            <v-list-item-content>
              {{ item.name }}
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-treeview>
      <!-- </template> -->
    </v-navigation-drawer>
  </div>
</template>

<script>
import Vue2Filters from "vue2-filters";
import i18n from "@/plugins/i18n";
import AppApi from "@/api/app-api";

export default {
  mixins: [Vue2Filters.mixin],
  data() {
    return {
      drawer: true,
      nextId: 1000,
      top: 0,
    };
  },

  methods: {
    addSubMenusRecursive(item) {
      let subMenus = AppApi.getMenusByMenuId(item.menuId);
      if (subMenus.length > 0) {
        if (!item.children) {
          this.$set(item, "children", []);
        }

        if (item.children && item.children.length == 0) {
          subMenus.sort((a, b) => {
            return a.menuOrderNo > b.menuOrderNo ? 1 : -1;
          });

          subMenus.forEach((element) => {
            let menu = {
              id: 0,
              bookId: element.bookId,
              menuId: element.menuId,
              parentMenuId: element.parentMenuId,
              menuType: 4, // book
              isSection: element.isSection,
              name: element.name,
              active: false,
              latinName: element.latinName,
              link: element.pageStartNo + "_" + element.paragraphNo,
            };

            this.addChild(item, menu);
            this.addSubMenusRecursive(menu);
          });
        }
      }
      return item;
    },

    updateLeftMenu(item) {
      AppApi.setCurrentMenu(null);

      if (item.menuType == 0) {
        AppApi.setCurrentBook(null);

        AppApi.setCurrentLanguage(null);
      }

      if (item.menuType == 1) {
        // this.overlay = true;
        // console.log("overlay : ", this.overlay);
        AppApi.setCurrentBook(null);

        this.selectedLanguage = this.$store.state.Languages.filter((b) => {
          return b.languageId == item.menuId;
        })[0];

        if (this.selectedLanguage) {
          AppApi.setCurrentLanguage(this.selectedLanguage);

          i18n.locale = this.selectedLanguage.code;
          if (this.selectedLanguage.direction == "RTL") {
            this.$vuetify.rtl = true;
          } else {
            this.$vuetify.rtl = false;
          }

          // this.overlay = false;
        }

        if (this.items[0].children.length > 0) {
          let asd = this.findItem(item.id, this.items);

          this.items[0].children.forEach((dfg) => {
            if (dfg.id != asd.id) {
              dfg.children = [];
            }
          });
        }
        if (item.children.length == 0) {
          AppApi.setMenusByLanguageId(item.menuId).then(() => {
            AppApi.setBooks();
            this.addBooks(item);
          });
        }
      } else if (item.menuType == 2 || item.menuType == 3) {
        this.selectedBook = this.$store.state.Books.filter((b) => {
          return b.bookId == item.bookId;
        })[0];
        const menus = AppApi.getMenusByMenuId(item.menuId);
        menus.sort((a, b) => {
          return a.menuOrderNo > b.menuOrderNo ? 1 : -1;
        });

        let nonSectionMenus = menus.filter((sd) => {
          return sd.isSection == false;
        });

        if (nonSectionMenus && nonSectionMenus.length > 0 && item.isSection) {
          this.selectedMenu = item;
          if (this.isMobile) {
            this.addSubMenusRecursive(item);
          } else {
            if (item.children) {
              item.children = [];
            }
          }
        } else if (item.menuType == 2 || item.menuType == 3) {
          this.selectedMenu = item;
          if (item.children && item.children.length == 0) {
            this.addBooksMenu(item);
          }
        }
      }
    },

    addChild(item, child) {
      const id = this.nextId++;
      child.id = id;
      item.children.push(child);
    },

    findItem(id, items = null) {
      if (!items) {
        items = this.items;
      }

      return items.reduce((acc, item) => {
        if (acc) {
          return acc;
        }

        if (item.id === id) {
          return item;
        }

        if (item.children) {
          return this.findItem(id, item.children);
        }

        return acc;
      }, null);
    },

    findItemByMenuId(menuId, items = null) {
      if (!items) {
        items = this.items;
      }

      return items.reduce((acc, item) => {
        if (acc) {
          return acc;
        }

        if (item.menuId == menuId) {
          return item;
        }

        if (item.children) {
          let result = this.findItemByMenuId(menuId, item.children);
          return result;
        }

        return acc;
      }, null);
    },

    addBooks(item) {
      // item is Language
      if (item.menuType == 1) {
        const books = this.$store.getters.getBooks;
        books.sort((a, b) => {
          return a.menuOrderNo > b.menuOrderNo ? 1 : -1;
        });
        books.forEach((element) => {
          let menu = {
            menuId: element.menuId,
            id: 0,
            bookId: element.bookId,
            parentMenuId: item.menuId,
            menuType: 2, // book
            isSection: true,
            name: element.name,
            active: false,
            sanitizedName: element.sanitizedName,
          };

          let subMenus = AppApi.getMenusByMenuId(menu.menuId);
          if (subMenus.length > 0) {
            this.$set(menu, "children", []);
          }

          this.addChild(item, menu);
        });
      }
    },

    addBooksMenu(item) {
      // item is book
      const menus = AppApi.getMenusByMenuId(item.menuId);
      menus.sort((a, b) => {
        return a.menuOrderNo > b.menuOrderNo ? 1 : -1;
      });

      let nonSectionMenus = menus.filter((sd) => {
        return sd.isSection == false;
      });

      if (nonSectionMenus.length == 0) {
        if (!item.children && menus.length > 0) {
          this.$set(item, "children", []);
        }
        menus.forEach((element) => {
          let menu = {
            id: 0,
            bookId: element.bookId,
            menuId: element.menuId,
            parentMenuId: element.parentMenuId,
            menuType: 3, // book
            isSection: true,
            name: element.name,
            active: false,
            sanitizedName: element.sanitizedName,
          };

          this.addChild(item, menu);
        });
      }
    },
  },
  computed: {
    active: {
      get() {
        return this.$store.getters.getActiveMenus;
      },
      set(val) {
        AppApi.setActiveMenus(val);
      },
    },
    open: {
      get() {
        return this.$store.getters.getOpenMenus;
      },

      set(val) {
        AppApi.setOpenMenus(val);
      },
    },
    items: {
      get() {
        return this.$store.getters.getLitems;
      },
      set(val) {
        AppApi.setLitems(val);
      },
    },
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown ? true : false;
    },
    isRtl() {
      return this.$vuetify.rtl;
    },
    selectedMenu: {
      get() {
        return this.$store.getters.getCurrentMenu;
      },
      set(val) {
        if (val.menuType != 0 || val.menuType != 1) {
          AppApi.setCurrentMenu(val);
          // AppApi.setOtherLanguageLinksLeftMenu(val.menuId);
          // if (val.isSection && val.menuType != 2) {
          //   AppApi.SetSoundFilesByMenuId(val.menuId);
          // }
        }
      },
    },
    selectedBook: {
      get() {
        return this.$store.getters.getCurrentBook;
      },
      set(val) {
        AppApi.setCurrentBook(val);
      },
    },
    selectedLanguage: {
      get() {
        return this.$store.getters.getCurrentLanguage;
      },
      set(val) {
        AppApi.setCurrentLanguage(val);
        AppApi.SetAllBookReadersByLanguageId(val.languageId);
      },
    },
  },

  async mounted() {
    this.unwatch = this.$store.watch(
      (state, getters) => getters.getLeftMenuState,
      (oldValue, newValue) => {
        if (oldValue != newValue) {
          this.drawer = !this.drawer;
        }
      }
    );
  },
  beforeDestroy() {
    this.unwatch();
  },
  updated() {
    // console.log("lmenu updated : ");
  },
};
</script>

<style>
/* .v-application a {
  color: #0f0f0fa8 !important;
} */
.v-treeview-node__label {
  font-size: 14px !important;
  white-space: pre-line !important;
}

.v-treeview-node__level {
  width: 8px !important;
}

.theme--dark.v-navigation-drawer {
  background-color: #1a1249 !important;
}

.theme--dark.v-application {
  background: #1a1249 !important;
}
.theme--dark.v-btn.v-btn--has-bg {
  background-color: #3c336c;
  color: white;
}

.theme--dark.v-sheet {
  background-color: #1a1249;
  border-color: #1e1e1e;
  color: #ffffff;
}

.theme--dark.v-text-field--solo > .v-input__control > .v-input__slot {
  background-color: #1a1249;
  color: white;
}

.theme-dark.v-input {
  background-color: #1a1249;
  color: white;
}

.theme-dark.v-select {
  background-color: #1a1249;
  color: white;
}

.theme-dark.v-text-field {
  background-color: #1a1249;
  color: white;
}

.theme--dark.v-expansion-panels .v-expansion-panel {
  background-color: #3b2d90;
  color: white;
}

.theme--dark.v-list {
  background: #1a1249;
  color: #ffffff;
}

.theme--light.v-navigation-drawer {
  background-color: #f9faf3 !important;
}

.theme--light.v-application {
  background: #f9faf3 !important;
}

.theme--light.v-btn.v-btn--has-bg {
  background-color: #1a1249;
  color: white;
}
</style>
