import Vue from "vue";
import Vuex from "vuex";
import pathify, { make } from "vuex-pathify";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [pathify.plugin],
  state: {
    SiteSettings: null,
    OpenMenus: [],
    ActiveMenus: [],
    LeftMenuState: true,
    Languages: Array<Language>(),
    CurrentLanguage: null,
    Books: Array<Book>(),
    CurrentBook: null,
    LanguageMenus: [],
    CurrentMenu: null,
    OtherLanguageLinks: [],
    SoundFiles: [],
    AllBookReaders: [],
    CurrentSoundFile: null,
    SoundFileCurrentTime: 0,
    CurrentBookReader: null,
    CurrentBookReaders: [],
    SearchText: "",
    Litems: [
      {
        id: 0,
        name: "Diller",
        menuType: 0,
        menuId: 0,
        children: [],
      },
    ],
    scrolling: false,
  },
  mutations: {
    setSiteSettings(state, siteSettings) {
      state.SiteSettings = siteSettings;
    },

    setSoundFileCurrentTime(state, soundFileCurrentTime) {
      state.SoundFileCurrentTime = soundFileCurrentTime;
    },
    setCurrentBookReaders(state, currentBookReaders) {
      state.CurrentBookReaders = currentBookReaders;
    },
    setActiveMenus(state, activeMenus) {
      state.ActiveMenus = activeMenus;
    },
    setOpenMenus(state, openMenus) {
      state.OpenMenus = openMenus;
    },
    setLitems(state, litems) {
      state.Litems = litems;
    },
    setLeftMenuState(state, lmState) {
      state.LeftMenuState = lmState;
    },
    setCurrentLanguage(state, language) {
      state.CurrentLanguage = language;
    },
    setCurrentBook(state, selectedBook) {
      state.CurrentBook = selectedBook;
    },
    setCurrentMenu(state, selectedMenu) {
      state.CurrentMenu = selectedMenu;
    },
    setCurrentSoundFile(state, payLoad) {
      state.CurrentSoundFile = payLoad;
    },
    setSearchText(state, payLoad) {
      state.SearchText = payLoad;
    },
    setBooks(state, payLoad) {
      state.Books = payLoad;
    },
    setLanguages(state, payLoad) {
      state.Languages = payLoad;
    },
    setSoundFiles(state, payLoad) {
      state.SoundFiles = payLoad;
    },
    setAllBookReaders(state, payLoad) {
      state.AllBookReaders = payLoad;
    },
    setCurrentBookReader(state, payLoad) {
      state.CurrentBookReader = payLoad;
    },
    setOtherLanguageLinks(state, payLoad) {
      state.OtherLanguageLinks = payLoad;
    },

    setLanguageMenus(state, payLoad) {
      state.LanguageMenus = payLoad;
    },
  },
  getters: {
    getSiteSettings(state) {
      return state.SiteSettings;
    },

    getSoundFileCurrentTime(state) {
      return state.SoundFileCurrentTime;
    },
    getCurrentBookReaders(state) {
      return state.CurrentBookReaders;
    },
    getActiveMenus(state) {
      return state.ActiveMenus;
    },
    getOpenMenus(state) {
      return state.OpenMenus;
    },

    getLitems(state) {
      return state.Litems;
    },

    getLeftMenuState(state) {
      return state.LeftMenuState;
    },
    getCurrentLanguage(state) {
      return state.CurrentLanguage;
    },
    getCurrentBook(state) {
      return state.CurrentBook;
    },
    getCurrentMenu(state) {
      return state.CurrentMenu;
    },
    getCurrentSoundFile(state) {
      return state.CurrentSoundFile;
    },
    getCurrentBookReader(state) {
      return state.CurrentBookReader;
    },
    getLanguageMenus(state) {
      return state.LanguageMenus;
    },
    getLanguages(state) {
      return state.Languages;
    },
    getBooks(state) {
      return state.Books;
    },

    getSearchText(state) {
      return state.SearchText;
    },
    getAllBookReaders(state) {
      return state.AllBookReaders;
    },
    getOtherLanguageLinks(state) {
      return state.OtherLanguageLinks;
    },
  },
  actions: {
    // setScrolling({ commit, state }, scrolling) {
    //   commit("setScrolling", scrolling);
    // },
    // async setLanguagesa({ commit, state }, languages) {
    //   commit("setLanguages", languages);
    // },
    // async setCurrentLanguagea({ commit, state }, language) {
    //   commit("setCurrentLanguage", language);
    //   const langId = language.languageId;
    //   this.dispatch("setBooksa", langId);
    // },
    // async setBooksa({ commit, state }, books) {
    //   commit("setBooks", books);
    // },
  },
  modules: {},
});
