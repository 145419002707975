import http from "./api-client";
// import { mapActions } from "vuex";
import store from "@/stores";
import i18n from "@/plugins/i18n";
import vuetify from "@/plugins/vuetify";

class AppApi {
  async getSiteSettings() {
    return await http.get("/SiteSettings/GetSiteSttings").then((response) => {
      store.commit("setSiteSettings", response.data);
      return response.data;
    });
  }

  getCurrentSiteSettings() {
    return store.state.SiteSettings;
  }

  async getStartPageContent() {
    return await http
      .get("/SiteSettings/GetStartPageContent")
      .then((response) => {
        return response.data;
      });
  }

  isRtl() {
    const currentLanguage: any = store.state.CurrentLanguage;
    // console.log("isRTL  currentLanguage: ", currentLanguage);
    vuetify.framework.rtl = false;

    if (currentLanguage) {
      i18n.locale = currentLanguage.code;
      if (currentLanguage.direction == "RTL") {
        vuetify.framework.rtl = true;
      }
    }
  }

  getCurrentSoundFile() {
    return store.state.CurrentSoundFile;
  }

  getCurrentTime() {
    return store.state.SoundFileCurrentTime;
  }

  async SetAllBookReadersByLanguageId(languageId: number) {
    const siteSettings: any = store.state.SiteSettings;
    if (siteSettings != null && siteSettings.isPlayerActive) {
      return await http
        .get("/BookReaders/GetBookReadersByLanguagenId/" + languageId)
        .then((response) => {
          store.commit("setAllBookReaders", response.data);
          return response.data;
        });
    }
  }

  async SetSoundFilesByMenuId(menuId: number) {
    // console.log("menuId for sound file: ", menuId);
    const siteSettings: any = store.state.SiteSettings;
    // console.log("siteSettings : ", siteSettings);
    if (siteSettings != null && siteSettings.isPlayerActive) {
      return await http
        .get("/SoundFiles/GetSoundFilesByMenuId/" + menuId)
        .then((response) => {
          store.commit("setSoundFiles", response.data);

          return response.data;
        })
        .then(() => {
          this.SetCurrentBookReaders();
        })
        .then(() => {
          this.setSoundFile(menuId);
        });
    }
  }

  setSoundFile(menuId: number) {
    const bookReaders = store.state.CurrentBookReaders as any[];
    const soundFiles = store.state.SoundFiles as any[];

    const soundFile = soundFiles.filter((item: any) => {
      return item.order == 1;
    })[0];

    store.commit("setCurrentSoundFile", soundFile);

    const bookReader = bookReaders.filter((item: any) => {
      return item.bookReaderId == soundFile.bookReaderId;
    })[0];
    store.commit("setCurrentBookReader", bookReader);

    store.commit("setSoundFileCurrentTime", 0);
  }

  async SetSoundFileTime(menuId: number, isLeft: boolean) {
    // const bookReaders = store.state.CurrentBookReaders as any[];
    // const soundFiles = store.state.SoundFiles as any[];

    // let soundFile = store.state.CurrentSoundFile as any;
    const bookReader = store.state.CurrentBookReader as any;

    if (bookReader == null) return;

    // if (isLeft) {
    //   soundFile = null;
    //   bookReader = null;
    // }

    // if (bookReader != null && soundFile != null) {
    //   if (soundFile.bookReaderId != bookReader.bookReaderId) {
    //     soundFile = soundFiles.filter((item: any) => {
    //       return item.bookReaderId == bookReader.bookreaderId;
    //     })[0];
    //     store.commit("setCurrentSoundFile", soundFile);
    //   }
    // } else if (soundFile == null || bookReader == null) {
    //   soundFile = soundFiles.filter((item: any) => {
    //     return item.order == 1;
    //   })[0];
    //   store.commit("setCurrentSoundFile", soundFile);

    //   bookReader = bookReaders.filter((item: any) => {
    //     return item.bookReaderId == soundFile.bookReaderId;
    //   })[0];
    //   store.commit("setCurrentBookReader", bookReader);
    // }

    // console.log("bookReader : ", bookReader);
    return await http
      .get(
        "/SoundFiles/GetSoundFileTimeByMenuIdAndReaderId/" +
          menuId +
          "/" +
          bookReader.bookReaderId
      )
      .then((response) => {
        //console.log("Api setSoundFileCurrentTime : ", response.data);
        store.commit("setSoundFileCurrentTime", response.data);
        return response.data;
      });
  }

  SetCurrentBookReaders() {
    const allBookReaders = store.state.AllBookReaders;
    //console.log("allBookReaders : ", allBookReaders);
    const soundFiles = store.state.SoundFiles;
    //console.log("soundFiles : ", soundFiles);

    const currentBookReaders = [] as any;
    soundFiles.forEach((itemb: any) => {
      const bookReader = allBookReaders.filter((item: any) => {
        return item.bookReaderId == itemb.bookReaderId;
      })[0];

      if (bookReader != null) {
        currentBookReaders.push(bookReader);
      }
    });
    //console.log("set bookreaders : ", currentBookReaders);
    store.commit("setCurrentBookReaders", currentBookReaders);
  }

  SetCurrentBookReader(bookReaderId: number) {
    const currentBookReaders = store.state.CurrentBookReaders;

    const currentBookReader = currentBookReaders.filter((item: any) => {
      return item.readerId == bookReaderId;
    })[0];
    store.commit("setCurrentBookReader", currentBookReader);
  }

  SetCurrentSoundFile(bookReaderId: number) {
    const soundFiles = store.state.SoundFiles;
    const currentSoundFile = soundFiles.filter((item: any) => {
      return item.bookReaderId == bookReaderId;
    })[0];
    store.commit("setCurrentSoundFile", currentSoundFile);
  }

  async GetPrevAndNextMenus(menuId: number) {
    return await http
      .get("/Menus/GetPrevAndNextMenus/" + menuId)
      .then((response) => {
        return response.data;
      });
  }

  async getSearchWordDicVariants(
    searchText: string,
    language: string,
    page: number,
    isVariantSearch: boolean,
    searchInVariantsFullText: boolean
  ) {
    const SearchDictionaryRequest = {
      SearchText: searchText,
      Language: language,
      Page: page,
      IsVariantSearch: isVariantSearch,
      SearchInVariantsFullText: searchInVariantsFullText,
    };

    return await http
      .post("/Search/SearchWordDicVariants", SearchDictionaryRequest)
      .then((response) => {
        // console.log("SearchWordDicVariants : ", response);
        return response.data;
      });
  }

  async getSearchAphorismsByPage(
    phrase: string,
    lang: string,
    page: number,
    selectedRootWords: [],
    selectedRootWordTypes: []
  ) {
    const SearchRequest = {
      Phrase: phrase,
      Lang: lang,
      Page: page,
      SelectedRootWords: selectedRootWords,
      SelectedRootWordTypes: selectedRootWordTypes,
    };

    return await http
      .post("/Search/SearchAllAphorisms", SearchRequest)
      .then((response) => {
        return response.data;
      });
  }

  async getSearchResultsByBook(
    phrase: string,
    languageId: number,
    bookId: number,
    specialSearch: boolean
  ) {
    console.log(" languageId api: ", languageId);
    return await http
      .get(
        "/Search/SearchBy/" +
          phrase +
          "/" +
          languageId +
          "/" +
          bookId +
          "/" +
          specialSearch
      )
      .then((response) => {
        return response.data;
      });
  }

  setActiveMenus(activeMenus: any) {
    store.commit("setActiveMenus", activeMenus);
  }

  setOpenMenus(openMenus: any) {
    store.commit("setOpenMenus", openMenus);
  }

  setLitems(litems: any) {
    store.commit("setLitems", litems);
  }

  getLitems() {
    return store.state.Litems;
  }

  async getContentByMenuId(menuId: any) {
    return await http
      .get("/Contents/GetContentByMenuId/" + menuId)
      .then((response) => {
        return response.data;
      });
  }

  async getLangAndBookByMenuId(menuId: any) {
    const sbc = { menuId: menuId, bookId: 0, languageId: 0 };
    return await http.get("/Menus/GetMenuById/" + menuId).then((response) => {
      const menu = response.data;
      // console.log("menu :", menu);
      sbc.bookId = menu.bookId;
      return http.get("/Books/GetBookById/" + menu.bookId).then((dfg) => {
        const book = dfg.data;
        // console.log("book : ", book);
        sbc.languageId = book.languageId;
        return sbc;
      });
      // console.log("content : " + response.data);
    });
  }

  setLeftMenuState(lstate: any) {
    store.commit("setLeftMenuState", lstate);
  }

  setCurrentMenu(item: any) {
    if (item == null) {
      this.setOtherLanguageLinksLeftMenu(0);
    }

    store.commit("setCurrentMenu", item);

    const siteSettings: any = store.state.SiteSettings;
    const curMenu: any = store.state.CurrentMenu;
    const curBook: any = store.state.CurrentBook;

    document.title =
      (siteSettings ? siteSettings.siteName : "Online Books") +
      " " +
      (curBook ? curBook.name : "") +
      " " +
      (curMenu ? curMenu.name : "-");
  }

  getCurrentMenu() {
    return store.state.CurrentMenu;
  }

  async setLanguages() {
    return await http.get("/Languages").then((response) => {
      store.commit("setLanguages", response.data);
      return response.data;
    });
  }

  getLanguages() {
    return store.state.Languages;
  }

  getBooks() {
    return store.state.Books;
  }

  setCurrentLanguage(language: any) {
    store.commit("setCurrentLanguage", language);
  }

  getCurrentLanguage() {
    return store.state.CurrentLanguage;
  }

  setCurrentBook(book: any) {
    // console.log("App-api-book: ", book);
    store.commit("setCurrentBook", book);
    // this.setMenusByBookId(book.bookId);
  }

  getCurrentBook() {
    return store.state.CurrentBook;
  }

  async setMenusByLanguageId(languageId: number) {
    // console.log("languageId : " + languageId);

    await http
      .get("/Menus/GetMenusBylanguageId/" + languageId)
      .then((response) => {
        store.commit("setLanguageMenus", response.data);
      });
  }

  setBooks() {
    const languageMenus = store.state.LanguageMenus;

    const books = languageMenus.filter((item: any) => {
      return item.parentMenuId == null;
    });
    store.commit("setBooks", books);
    return books;
  }

  getMenusByMenuId(parentMenuId: number) {
    const languageMenus = store.state.LanguageMenus;
    const subMenus = languageMenus.filter((item: any) => {
      return item.parentMenuId == parentMenuId;
    });
    // console.log("subMenus : " + subMenus);
    return subMenus;
  }

  getMenuById(menuId: number) {
    const languageMenus = store.state.LanguageMenus;
    const menu = languageMenus.filter((item: any) => {
      return item.menuId == menuId;
    });

    return menu[0];
  }

  async GetMenuByHash(menuId: number, hashTag: string) {
    // console.log("menuId,hashtag : ", menuId, hashTag);
    return await http
      .get("/OtherMenus/GetMenuByMenuIdAndHash/" + menuId + "/" + hashTag)
      .then((response) => {
        return response.data;
      });
  }

  async setOtherLanguageLinksByHashTag(menuId: number, hashTag: string) {
    // console.log("menuId,hashtag : ", menuId, hashTag);

    const languages = store.state.Languages;

    if (languages != null && languages.length > 1 && menuId > 0) {
      await http
        .get(
          "/OtherMenus/GetOtherLanguageMenusByHash/" + menuId + "/" + hashTag
        )
        .then((response) => {
          store.commit("setOtherLanguageLinks", response.data);
        });
    }
  }

  async setOtherLanguageLinksRigthMenu(
    currentMenuId: number,
    subMenuId: number
  ) {
    const languages = store.state.Languages;
    if (
      currentMenuId > 0 &&
      subMenuId > 0 &&
      languages != null &&
      languages.length > 1
    ) {
      await http
        .get(
          "/OtherMenus/GetOtherLanguageMenusById/" +
            currentMenuId +
            "/" +
            subMenuId
        )
        .then((response) => {
          store.commit("setOtherLanguageLinks", response.data);
        });
    }
  }

  async setOtherLanguageLinksLeftMenu(menuId: number) {
    const languages = store.state.Languages;
    if (menuId > 0 && languages != null && languages.length > 1) {
      await http
        .get("/OtherMenus/GetOtherLanguageLinksByMenuId/" + menuId)
        .then((response) => {
          store.commit("setOtherLanguageLinks", response.data);
        });
    }
  }

  setOtherLanguagesMenuNull() {
    store.commit("setOtherLanguageLinks", null);
  }

  getOtherLanguageLinks() {
    return store.state.OtherLanguageLinks;
  }

  // Tree Structure Helper
  toArray(nodes: any[], arr: any[]) {
    // console.log("nodes: ", nodes);
    if (!nodes) {
      return [];
    }
    if (!arr) {
      arr = [];
    }
    for (let i = 0; i < nodes.length; i++) {
      arr.push(nodes[i]);
      this.toArray(nodes[i].children, arr);
    }
    return arr;
  }
}

export default new AppApi();
