import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  rtl: false,
  theme: {
    dark: false,
    options: { customProperties: true },
    // themes: {
    //   dark: {
    //     primary: colors.red.darken1, // #E53935
    //     secondary: colors.red.lighten4, // #FFCDD2
    //     accent: colors.indigo.base, // #3F51B5
    //   },
    // },
  },
  // theme: {
  //   dark: false,
  //   options: { customProperties: true },
  // },
});
