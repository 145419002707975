import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
// import Config  from "@/config.json";
//const AUTH_TOKEN = "sozler 4911182d4c228ee6cd53bce03ec34c0a";

//const runEnv = process.env.NODE_ENV;
//let host = window.location.host;
//const spltHost = host.split(":");

//if (spltHost.length > 1) {
//  host = spltHost[0];
//}
//axios.defaults.baseURL = "http://" + host + ":8082/api/";

//if (runEnv == "development") {
//  // axios.defaults.baseURL = "http://istanbul.sozler.com.tr:8082/api/";
//  // axios.defaults.baseURL = "http://sozbasim.duckdns.org:8082/api/";
//  //axios.defaults.baseURL = "http://" + host + ":8082/api/";
//    axios.defaults.baseURL = "/api/";
//}
//if (runEnv == "production") {
//    //axios.defaults.baseURL = "http://" + host + ":8082/api/";
//    axios.defaults.baseURL = "/api/";
//}

axios.defaults.baseURL = "/api/";

// axios.defaults.headers.common["Access-Control-Allow-Origin"] =  "http://sozbasim:8080";
//axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
axios.defaults.headers.get["Content-type"] = "application/json";
axios.defaults.headers.common["Accept"] = "application/json";

const config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || "",
  timeout: 90 * 1000, // Timeout
  withCredentials: true, // Check cross-site Access-Control
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
};

const apiClient: AxiosInstance = axios.create(config);
// const apiClient: AxiosInstance = axios.create({
//   baseURL: "http://localhost:5000/api/",
//   headers: {
//     "Content-type": "application/json",
//     Authorization: AUTH_TOKEN,
//     Accept: "application/json",
//   },
//   withCredentials: true,
// });



apiClient.interceptors.request.use(
  // Do something before request is sent

    (conf: AxiosRequestConfig) => conf,
        // Do something with request error
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (error: any) => Promise.reject(error)
);

export default apiClient;
