<template>
  <div class="md-typeset mt-5">
    <div v-if="contenta == null && overlay == false">
      <span v-html="'<p3><f1>Sayfa Bulunamadı</f1></p3>'"> </span>
    </div>

    <!-- <div v-if="contenta == null && overlay == true">
      <span v-html="'<p3><f1>Sayfa Yükleniyor</f1></p3>'"> </span>
    </div> -->

    <div
      v-if="contenta != null && searchText != null"
      class="md-container"
      v-html="highlightba(contenta, searchText)"
    ></div>

    <div
      v-if="contenta != null && searchText == null"
      class="md-container"
      v-html="contenta"
    ></div>

    <!-- <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on"> Button </v-btn>
      </template>
      <span>Tooltip</span>
    </v-tooltip> -->

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="128"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import AppApi from "../api/app-api";

export default {
  name: "Content",
  components: {},
  data: () => ({
    contenta: null,
    menuIda: 0,
    currHashTag: "",
    duration: 500,
    offset: 0,
    easing: "easeInOutCubic",
    top: 0,
    overlay: false,
  }),
  // metaInfo() {
  //   return {
  //     meta: [{ name: "description", content: this.contenta }],
  //     link: [
  //       {
  //         rel: "canonical",
  //         href: "http://" + window.location.host + this.$route.fullPath,
  //       },
  //     ],
  //   };
  // },

  // filters: {
  //   // highlightb: function (contentb, query) {
  //   //   const iQuery = new RegExp(query, "ig");
  //   //   return contentb.toString().replace(iQuery, function (matchedTxt) {
  //   //     return "<span class='highlight'>" + matchedTxt + "</span>";
  //   //   });
  //   // },
  // },

  watch: {
    "$route.hash": {
      handler: function (hash) {
        // console.log("hash: ", hash);
        this.gotoScroll(hash);
      },
    },

    "$route.params.menuId": {
      handler: function (menuId) {
        // if (menuId != null && menuId != this.menuIda) {
        // console.log("menuId changed :");
        this.contenta = null;
        this.overlay = true;
        this.menuIda = parseInt(menuId);
        AppApi.getContentByMenuId(menuId).then((res) => {
          if (res != null && res.length > 0) {
            this.contenta = res;
            this.gotoScroll(this.$route.hash);
            this.overlay = false;
          } else {
            this.contenta = null;
            this.overlay = false;
          }
        });
        // }
      },
      // deep: true,
      immediate: true,
    },
  },
  computed: {
    searchText() {
      return this.$route.query.searchtext;
    },
    options() {
      return {
        duration: this.duration,
        offset: 0,
        easing: this.easing,
      };
    },
  },
  methods: {
    goToSavedScroll() {
      const scrolPos = localStorage.getItem("pagePosition");
      if (scrolPos == null) return;
      window.scrollTo(0, scrolPos);
      localStorage.removeItem("pagePosition");
    },
    highlightba(contentb, query) {
      const iQuery = new RegExp(query, "ig");
      return contentb.toString().replace(iQuery, function (matchedTxt) {
        return "<span class='highlight'>" + matchedTxt + "</span>";
      });
    },

    gotoScroll(target) {
      this.$nextTick(() => {
        if (target.length > 0) {
          // target = "#a_12_3";
          this.currHashTag = target;

          if (target.indexOf("fn") == -1) {
            if (this.menuIda != null && this.menuIda > 0) {
              AppApi.setOtherLanguageLinksByHashTag(
                this.menuIda,
                target.replace("#", "")
              );
            }
          }
          target = "[id ='" + target.replace("#", "") + "']";
          console.log("target: ", target);
          this.$vuetify.goTo(target, this.options);
        } else {
          this.goToSavedScroll();
          if (this.menuIda != null && this.menuIda > 0) {
            console.log("setOtherLinks Content..");
            AppApi.setOtherLanguageLinksLeftMenu(this.menuIda);
          }
        }
      });
    },
  },
  created() {
    window.addEventListener("beforeunload", function (event) {
      event.returnValue = "Write something";
      // console.log("refreshing");
      this.contenta = "";
      if (location.hash.length == 0) {
        localStorage.setItem("pagePosition", window.scrollY);
      }
    });
  },
};
</script>

<style scoped>
@import "../assets/css/app.css";
@import "../assets/css/override.css";

/* .something {
    color: var(--v-primary-base);
    background-color: var(--v-accent-lighten2);
  } */

.md-typeset .highlight {
    background-color: rgb(182 32 32 / 43%) !important;
}

.qwts-meaning {

    text-indent: 0.1em !important;
    text-decoration: none;
    color: #3366cc;
    margin-top: -4px;
    margin-bottom: -4px;
    margin-left: -4px;
    margin-right: -4px;
    line-height: 1.2em !important;
}
</style>
