
import Vue from "vue";
import SearchFooter from "./search-footer.vue";

export default Vue.extend({
  name: "SearchLayout",
  components: { SearchFooter },
  data: () => ({
    sidebar: false,
    menuItems: [
      {
        title: "standardSearch",
        path: "/search/search-by-book",
        icon: "mdi-magnify",
      },
      {
        title: "aphorismSearch",
        path: "/search/search-by-aphorism",
        icon: "mdi-magnify",
      },
      {
        title: "dictionarySearch",
        path: "/search/search-by-dictionary",
        icon: "mdi-magnify",
      },
    ],
  }),
  computed: {},
});
