import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  tr: {
    welcomeMsg: "Online Kitaplara Hoşgeldiniz",
    onlineBooks: "Online Kitaplar",
    placeHolder: "Aranacak kelimeyi giriniz",
    prev: "Önceki",
    next: "Sonraki",
    listen: "Dinle",
    page: "Sayfa",
    paragraph: "Paragraf",
    search: "Ara",
    standardSearch: "Standart Arama",
    aphorismSearch: "Kelime Analiz",
    dictionarySearch: "Sözlük",
    allBooks: "Tüm Kitaplar",
    customSearch: "Bitişik Arama",
    rootWords: "Kök Kelimeler",
    rootWordTypes: "Kök Kelime Tipleri",
    withInWords: "Kelimelerde",
    fullText: "Full Text",
    withInMeanings: "Anlamlarda",
    contents: "İçindekiler",
    total: "Toplam",
  },
  en: {
    welcomeMsg: "Welcome to Online Books",
    onlineBooks: "Online Books",
    placeHolder: "Type the word to search",
    prev: "Previous",
    next: "Next",
    listen: "Listen",
    page: "Page",
    paragraph: "Paragraph",
    search: "Search",
    standardSearch: "Standard Search",
    aphorismSearch: "Word Analysis",
    dictionarySearch: "Dictioanry",
    allBooks: "All Books",
    customSearch: "Custom Search",
    rootWords: "Root Words",
    rootWordTypes: "Root Word Types",
    withInWords: "With In Words",
    fullText: "Full Text",
    withInMeanings: "With In Meanings",
    contents: "Contents",
    total: "Total",
  },
  ru: {
    welcomeMsg: "Добро пожаловать в онлайн-книги",
    onlineBooks: "онлайн книги",
    placeHolder: "Введите слово для поиска",
    prev: "предыдущий",
    next: "следующий",
    listen: "Слушать",
    page: "страница",
    paragraph: "параграф",
    search: "поиск",
    standardSearch: "стандартный поиск",
    aphorismSearch: "Анализ слов",
    dictionarySearch: "Словарь",
    allBooks: "все книги",
    customSearch: "пользовательский поиск",
    rootWords: "корневые слова",
    rootWordTypes: "корневые типы слов",
    withInWords: "словами",
    fullText: "полный текст",
    withInMeanings: "со значениями",
    contents: "содержание",
    total: "Всего",
  },
  ar: {
    welcomeMsg: "مرحبًا بك في الكتب عبر الإنترنت",
    onlineBooks: "كتب على الإنترنت",
    placeHolder: "أدخل كلمة البحث",
    prev: "السابق",
    next: "التالي",
    listen: "يستمع",
    page: "صفحة",
    paragraph: "فقرة",
    search: "بحث",
    standardSearch: "البحث القياسي",
    aphorismSearch: "تحليل الكلمات",
    dictionarySearch: "قاموس",
    allBooks: "كل الكتب",
    customSearch: "بحث مخصص",
    rootWords: "أصول الكلمات",
    rootWordTypes: "أنواع الكلمات الجذرية",
    withInWords: "بالكلمات",
    fullText: "نص كامل",
    withInMeanings: "مع في المعاني",
    contents: "محتويات",
    total: "مجموع",
  },
  es: {
    welcomeMsg: "Bienvenido a Libros en línea",
    onlineBooks: "Libros en línea",
    placeHolder: "Introduzca la palabra de búsqueda",
    prev: "anterior",
    next: "Siguiente",
    listen: "escucha",
    page: "página",
    paragraph: "párrafo",
    search: "búsqueda",
    standardSearch: "Busqueda estandar",
    aphorismSearch: "Análisis de palabras",
    dictionarySearch: "diccionario",
    allBooks: "todos los libros",
    customSearch: "Búsqueda contigua",
    rootWords: "palabras raíz",
    rootWordTypes: "Tipos de palabras raíz",
    withInWords: "con en palabras",
    fullText: "Texto completo",
    withInMeanings: "con significados",
    contents: "contenido",
    total: "total",
  },
};

export default new VueI18n({
  locale: "tr", // set locale
  fallbackLocale: "en", // set fallback locale
  messages, // set locale messages
});
