
import Vue from "vue";
import LeftMenu from "./left-menu.vue";
import AppHeader from "./app-header.vue";
import AppFooter from "./app-footer.vue";
import RigthMenu from "./rigth-menu.vue";

export default Vue.extend({
  name: "AppLayout",
  components: { AppHeader, AppFooter, RigthMenu, LeftMenu },
  data: () => ({}),
  computed: {
    isMobile() {
      // return false;
      return this.$vuetify.breakpoint.mdAndDown ? true : false;
    },
  },
});
