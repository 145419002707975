<template>
  <div class="md-typeset mt-10">
    <div class="md-container">
      <v-container>
        <v-sheet min-height="100vh" rounded="lg">
          <!--  -->
          <v-text-field
            v-model="searchTextVue"
            autocomplete="on"
            :label="$t('placeHolder')"
            :placeholder="$t('search')"
            outlined
            v-on:keyup.enter="getSearchResults"
          ></v-text-field>

          <hr />
          <v-radio-group v-model="selectedSearch" column>
            <div>
              <v-radio
                id="word"
                :label="$t('withInWords')"
                value="isVariantSearch"
              ></v-radio>

              <v-checkbox
                v-if="isVariantSearch"
                v-model="searchInVariantsFullText"
                :label="$t('fullText')"
              ></v-checkbox>
            </div>
            <v-radio
              id="meaning"
              :label="$t('withInMeanings')"
              value="isMeaningSearch"
            ></v-radio>
          </v-radio-group>
          <hr />
          <v-btn @click.prevent="searchClicked">{{ $t("search") }}</v-btn>
          <hr />

          <v-expansion-panels focusable>
            <v-expansion-panel
              v-for="item in searchResults"
              :key="item.rootWord.rootWordId"
            >
              <v-expansion-panel-header class="text-h5">
                {{ item.rootWord.name }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <span
                  style="padding-left: 2px"
                  v-for="variant in item.rootVariants"
                  :key="variant.order"
                  >{{ variant.wordName }} /
                </span>
                <ol>
                  <li
                    style="padding-left: 15px"
                    v-for="meaning in item.rootWordMeanings"
                    :key="meaning.order"
                  >
                    {{ meaning.meaningText }}
                  </li>
                </ol>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-pagination
            v-model="page"
            class="my-4"
            :length="pageCount"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
          ></v-pagination>
        </v-sheet>
      </v-container>
    </div>
  </div>
</template>

<script>
import AppApi from "../api/app-api";
import Vue2Filters from "vue2-filters";

export default {
  name: "SearchDictionary",
  mixins: [Vue2Filters.mixin],

  data: () => ({
    searchTextVue: "",
    searchResults: [],
    page: 1,
    language: "tr",
    isLoading: false,
    recordCount: 0,
    perPage: 10,
    noData: true,
    isVariantSearch: true,
    searchInVariantsFullText: false,
    isMeaningSearch: false,
    selectedSearch: "isVariantSearch",
  }),

  methods: {
    getSearchResults() {
      if (this.searchTextVue != "" && this.languageCode != "") {
        this.isLoading = true;
        this.searchResults = [];
        this.recordCount = 0;
        // console.log(`Load searchs page: ${this.page}`);
        // console.log(this.searchTextVue);
        // console.log(this.language);

        AppApi.getSearchWordDicVariants(
          this.searchTextVue,
          this.language,
          this.page,
          this.isVariantSearch,
          this.searchInVariantsFullText
        )
          .then((response) => {
            this.noData = false;

            // console.log(response);
            if (response != null) {
              this.recordCount = response.recordCount;
              this.searchResults = response.rootWordResultViews;

              this.isLoading = false;

              if (this.recordCount == 0) {
                this.noData = true;
                this.isLoading = false;
              }

              // console.log("res: ", response);
            }
          })
          .catch(function (error) {
            console.log(error);
            this.noData = true;
            this.isLoading = false;
          });
      }
    },

    clickCallback: function (pageNum) {
      console.log(pageNum);
    },

    clearValues() {
      this.page = 1;
      this.searchResults = [];
      this.noData = false;
    },

    searchClicked: function (e) {
      e.preventDefault();
      this.getSearchResults();
    },
  },
  watch: {
    page: function (val) {
      if (val > 0) {
        this.searchResults = [];
        this.getSearchResults();
      }
    },

    selectedSearch: function (val) {
      this.clearValues();
      if (val === "isVariantSearch") {
        this.isMeaningSearch = false;
        this.isVariantSearch = true;
      } else {
        this.isMeaningSearch = true;
        this.isVariantSearch = false;
        this.searchInVariantsFullText = false;
      }
    },
    searchInVariantsFullText: function () {
      this.clearValues();
    },

    searchTextVue: function () {
      this.clearValues();
    },
  },

  computed: {
    pageCount() {
      if (this.recordCount > 0) {
        return Math.ceil(this.recordCount / this.perPage);
      }
      return 0;
    },
    languageCode() {
      const language = AppApi.getCurrentLanguage();
      // console.log("language: ", language);
      if (language != null) {
        const code = language.code;
        if (code != null && code.length > 0) {
          return code;
        }
      }
      return "tr";
    },
  },
};
</script>
