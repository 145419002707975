<template>
  <v-app-bar
    app
    dark
    clipped-left
    clipped-right
    extension-height="64"
    color="red"
    elevation="5"
  >
    <v-app-bar-nav-icon @click="setDrawer()"></v-app-bar-nav-icon>

    <router-link to="/">
      <v-btn class="mx-1" color="white" text>
        <v-icon>mdi-home</v-icon>
      </v-btn>
    </router-link>

    <v-toolbar-title>
      <span class="title">{{ siteName }}</span>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-spacer v-if="!isMobile"></v-spacer>
    <search-input v-if="!isMobile" v-show="currentLanguage"></search-input>
    <v-spacer v-if="!isMobile"></v-spacer>

    <o-language-menu class="ma-2" />
    <v-btn icon @click="toggle_dark_mode">
      <v-icon>mdi-theme-light-dark</v-icon>
    </v-btn>

    <template v-slot:extension v-if="isMobile">
      <search-input v-show="currentLanguage"></search-input>
    </template>
  </v-app-bar>
</template>

<script>
import OLanguageMenu from "@/components/OLanguageMenu.vue";
import SearchInput from "@/components/SearchInput.vue";
import AppApi from "@/api/app-api";

export default {
  components: { OLanguageMenu, SearchInput },
  data: () => ({
    drawer: true,
    currentLanguage: null,
  }),
  methods: {
    setDrawer() {
      this.drawer = !this.drawer;
      AppApi.setLeftMenuState(this.drawer);
    },
    toggle_dark_mode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark);
    },
  },
  mounted() {
    this.unwatch = this.$store.watch(
      (state, getters) => getters.getCurrentLanguage,
      (newValue) => {
        if (newValue) {
          // console.log("new Value : ", newValue);
          this.currentLanguage = newValue;
        } else {
          this.currentLanguage = null;
        }
      }
    );

    const theme = localStorage.getItem("dark_theme");
    if (theme) {
      if (theme === "true") {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    } else if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      this.$vuetify.theme.dark = true;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    }
  },
  beforeDestroy() {
    this.unwatch();
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown ? true : false;
    },
    siteName() {
      let sName = this.$store.state.SiteSettings.siteName;
      return sName;
    },
  },
};
</script>
