<template>
  <v-navigation-drawer
    id="rtoc"
    app
    :right="!isRtl"
    fixed
    width="20rem"
    clipped
  >
    <template v-slot:prepend>
      <v-sheet
        v-if="items.length > 0"
        color="red lighten-0 white-text"
        dark
        class="pa-3 md"
        height="64px"
      >
        <span class="title">{{ $t("contents") }}</span>
      </v-sheet>
    </template>

    <v-divider></v-divider>
    <v-treeview
      id="rigthMenu"
      v-if="items.length > 0"
      open-all
      :items="items"
      :active.sync="active"
      open-on-click
      color="red"
      item-text="black"
      activatable
      transition
    >
      <template slot="label" slot-scope="{ item }">
        <v-list-item
          @click.prevent="setOtherLangugeLinks(item.menuId)"
          :to="'#' + item.link"
        >
          <!-- <a :href="'#' + item.link" style="text-decoration: none"> -->
          <!-- {{ item.name }} -->
          <!-- </a> -->
          <v-list-item-content>
            {{ item.name }}
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-treeview>
  </v-navigation-drawer>
</template>

<script>
import AppApi from "@/api/app-api";
import { mapState } from "vuex";

export default {
  data: () => ({
    active: [],
    open: [],
    items: [],
    nextId: 1000,
    curMenu: null,
    currHashTag: "",
    flatMenus: [],
  }),
  methods: {
    setOtherLangugeLinks(subMenuId) {
      //AppApi.setOtherLanguageLinksRigthMenu(this.curMenu.menuId, subMenuId);
      //console.log("setotherlanglinks:  ", subMenuId);
      AppApi.SetSoundFileTime(subMenuId, false);
    },

    addSubMenusRecursive(item) {
      //   console.log("item", item);
      let subMenus = AppApi.getMenusByMenuId(item.menuId);
      if (subMenus.length > 0) {
        subMenus.sort((a, b) => {
          return a.menuOrderNo > b.menuOrderNo ? 1 : -1;
        });
        if (!item.children) {
          this.$set(item, "children", []);
        }
        // link: this.$vuetify.goTo("#dashboard", options),
        subMenus.forEach((element) => {
          let menu = {
            id: 0,
            bookId: element.bookId,
            menuId: element.menuId,
            parentMenuId: element.parentMenuId,
            menuType: 5, // book
            isSection: element.isSection,
            name: element.name,
            active: false,
            link: element.pageStartNo + "_" + element.paragraphNo,
          };

          this.addChild(item, menu);
          this.addSubMenusRecursive(menu);
        });
      }
      return item;
    },
    addChild(item, child) {
      // if (!item.children) {
      //   this.$set(item, "children", []);
      // }
      const id = this.nextId++;
      child.id = id;
      item.children.push(child);
    },
  },
  computed: {
    ...mapState(["getCurrentMenu"]),
    isRtl() {
      return this.$vuetify.rtl;
    },
  },
  updated() {
    // console.log("rmennu updated : ");
  },
  mounted() {
    this.unwatch = this.$store.watch(
      (state, getters) => getters.getCurrentMenu,
      (newValue) => {
        if (newValue) {
          this.curMenu = newValue;

          let subMenus = AppApi.getMenusByMenuId(newValue.menuId);
          let nonSectionMenus = subMenus.filter((sd) => {
            return sd.isSection == false;
          });

          if (nonSectionMenus && nonSectionMenus.length > 0) {
            this.items = [];
            let menu = {
              id: 0,
              bookId: newValue.bookId,
              menuId: newValue.menuId,
              parentMenuId: newValue.parentMenuId,
              menuType: 4, // right menu
              isSection: newValue.isSection,
              name: newValue.name,
              active: false,
              link: newValue.pageStartNo + "_" + newValue.paragraphNo,
            };

            if (subMenus.length) {
              this.$set(menu, "children", []);
            }

            let result = this.addSubMenusRecursive(menu);
            this.items.push(result);

            if (
              this.$route.hash.length > 0 &&
              this.currHashTag != this.$route.hash
            ) {
              this.currHashTag = this.$route.hash;

              this.flatMenus = AppApi.toArray(this.items, []);

              if (this.currHashTag.indexOf("fn") == -1) {
                AppApi.GetMenuByHash(
                  this.curMenu.menuId,
                  this.currHashTag.replace("#", "")
                ).then((res) => {
                  const menus = this.flatMenus.filter((item) => {
                    return item.menuId == res.menuId;
                  });

                  let menub = menus[0];
                  this.active = [menub.id];
                  AppApi.SetSoundFileTime(menub.menuId, false);
                });
              }
            }
          } else {
            this.items = [];
          }
        } else {
          this.items = [];
        }
      }
    );
  },
  beforeDestroy() {
    this.unwatch();
  },
};
</script>

<style scoped>
/* .v-application a {
  color: #0f0f0fa8 !important;
} */
/* .v-treeview-node__label {
  font-size: 14px !important;
  white-space: pre-line !important;
}
.v-treeview-node__level {
  width: 8px !important;
} */
</style>
