<template>
  <div class="md-typeset mt-10">
    <div class="md-container">
      <v-container>
        <v-row>
          <v-col cols="12" sm="2">
            <v-sheet
              rounded="lg"
              min-height="100vh"
              class="overflow-y-auto"
              max-height="100vh"
            >
              <!--  -->
              <p>{{ $t("rootWords") }}</p>
              <div v-for="root in rootWords" :key="root.rootWordId">
                <input
                  id="root.rootWordId"
                  type="checkbox"
                  :value="root.rootWordId"
                  v-model="selectedRootWords"
                />
                <label for="root.rootWordId"> {{ root.name }}</label>
              </div>
            </v-sheet>
          </v-col>

          <v-col cols="12" sm="8">
            <v-sheet
              min-height="100vh"
              rounded="lg"
              class="overflow-y-auto"
              max-height="100vh"
            >
              <!--  -->
              <v-text-field
                v-model="searchTextVue"
                autocomplete="on"
                :label="$t('placeHolder')"
                :placeholder="$t('search')"
                outlined
                v-on:keyup.enter="getSearchResults"
              ></v-text-field>
              <hr />
              <v-btn @click.prevent="searchClicked">{{ $t("search") }}</v-btn>
              <hr />
              <ol>
                <li
                  v-for="apho in searchResults"
                  :key="apho.aphorism.aphorismId"
                >
                  <p>{{ apho.aphorism.name }}</p>

                  <ol>
                    <li
                      v-for="lnk in apho.aphorismPageLinks"
                      :key="lnk.aphorismPageLinkId"
                    >
                      <router-link
                        :to="{
                          path: lnk.pageUrl,
                          query: { searchtext: searchTextVue },
                        }"
                        target="_blank"
                        >{{ lnk.pageUrl }}</router-link
                      >
                      <!-- 
                      <a
                        :href="lnk.pageUrl"
                        target="_blank"
                        style="padding-left: 15px"
                        >{{ lnk.pageUrl }}</a
                      > -->
                    </li>
                  </ol>
                </li>
              </ol>

              <v-pagination
                v-model="page"
                class="my-4"
                :length="pageCount"
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
              ></v-pagination>
            </v-sheet>
          </v-col>

          <v-col cols="12" sm="2">
            <v-sheet
              rounded="lg"
              min-height="100vh"
              class="overflow-y-auto"
              max-height="100vh"
            >
              <!--  -->
              <p>{{ $t("rootWordTypes") }}</p>
              <div
                v-for="rootType in orderBy(rootWordTypes, 'name')"
                :key="rootType.rootWordTypeId"
              >
                <input
                  id="rootType.rootWordTypeId"
                  type="checkbox"
                  :value="rootType.rootWordTypeId"
                  v-model="selectedRootWordTypes"
                />
                <label for="rootType.rootWordTypeId">
                  {{ rootType.name }}</label
                >
              </div>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import AppApi from "../api/app-api";
import Vue2Filters from "vue2-filters";

export default {
  name: "SearchAphorism",
  mixins: [Vue2Filters.mixin],

  data: () => ({
    searchTextVue: "",
    rootWords: [],
    rootWordTypes: [],
    searchResults: [],
    selectedRootWords: [],
    selectedRootWordTypes: [],
    language: "tr",
    isLoading: false,
    recordCount: 0,
    noData: true,
    page: 0,
    perPage: 10,
  }),

  methods: {
    getTotalsOfArray(items) {
      let total = 0;
      if (items && items.length > 0) {
        items.forEach((element) => {
          total += element.totalCount;
        });
      }

      return total;
    },

    getTotalParagraphsOfArray(items) {
      let total = 0;
      if (items && items.length > 0) {
        items.forEach((element) => {
          total += element.pargraphs.length;
        });
      }

      return total;
    },
    getSearchResults() {
      if (this.searchTextVue != "" && this.languageCode != "") {
        this.isLoading = true;
        this.searchResults = [];
        // console.log(`Load searchs page: ${this.page}`);
        // console.log(this.searchTextVue);

        AppApi.getSearchAphorismsByPage(
          this.searchTextVue,
          this.language,
          this.page,
          this.selectedRootWords,
          this.selectedRootWordTypes
        )
          .then((response) => {
            // res.books.sort((a, b) => {
            //   return a.book.orderNo > b.book.orderNo ? 1 : -1;
            // });

            let result = response;
            this.noData = false;

            //console.log(result);
            this.recordCount = result.recordCount;
            this.searchResults = result.aphorismLinkViews;

            if (this.rootWords.length == 0) {
              // result.rootWords.sort((a, b) => {
              //   return a.sanitizedName < b.sanitizedName ? 1 : -1;
              // });

              this.rootWords = result.rootWords;
            }
            if (this.rootWordTypes.length == 0) {
              this.rootWordTypes = result.rootWordTypes;
            }

            this.isLoading = false;

            if (this.recordCount == 0) {
              this.noData = true;
              this.isLoading = false;
            }

            // console.log("res: ", response);
          })
          .catch(function (error) {
            console.log(error);
            this.noData = true;
            this.isLoading = false;
          });
      }
    },

    clickCallback: function (pageNum) {
      console.log(pageNum);
    },

    clearValues() {
      this.page = 1;
      this.searchResults = [];
      this.noData = false;
    },

    searchClicked: function (e) {
      e.preventDefault();
      this.getSearchResults();
    },
  },
  watch: {
    page: function (val) {
      if (val > 0) {
        this.getSearchResults();
      }
    },
    searchTextVue: function () {
      this.rootWords = [];
      this.rootWordTypes = [];
      this.selectedRootWords = [];
      this.selectedRootWordTypes = [];

      this.clearValues();
    },

    selectedRootWords: function () {
      this.clearValues();
    },

    selectedRootWordTypes: function () {
      this.clearValues();
    },
  },

  computed: {
    pageCount() {
      if (this.recordCount > 0) {
        return Math.ceil(this.recordCount / this.perPage);
      }
      return 0;
    },
    languageCode() {
      const language = AppApi.getCurrentLanguage();
      // console.log("language: ", language);

      if (language != null) {
        const code = language.code;
        if (code != null && code.length > 0) {
          return code;
        }
      }

      return "tr";
    },
  },
};
</script>
