import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Content from "../views/Content.vue";
import StartPage from "../views/StartPage.vue";

import SearchByBook from "../views/SearchBook.vue";
import SearchByAphorism from "../views/SearchAphorism.vue";
import SearchByDictionary from "../views/SearchDictionary.vue";
import goTo from "vuetify/lib/services/goto";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: StartPage,
  },
  {
    path: "/:bookname/:menuname/:menuId",
    component: Content,
  },
  {
    path: "/search",
    component: SearchByBook,
    meta: { layout: "search" },
  },
  {
    path: "/search/search-by-book",
    component: SearchByBook,
    meta: { layout: "search" },
  },
  {
    path: "/search/search-by-aphorism",
    component: SearchByAphorism,
    meta: { layout: "search" },
  },
  {
    path: "/search/search-by-dictionary",
    component: SearchByDictionary,
    meta: { layout: "search" },
  },
  {
    path: "/:bookname/:menuId",
    component: Content,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
