
import Vue from "vue";
import ErrorHeader from "./error-header.vue";
import ErrorFooter from "./error-footer.vue";

export default Vue.extend({
  name: "ErrorLayout",
  components: { ErrorFooter, ErrorHeader },
  data: () => ({}),
  computed: {},
});
