<template>
  <div class="md-typeset mt-10">
    <div class="md-container">
      <div>
        <v-select
          :items="books"
          :label="$t('allBooks')"
          solo
          clearable
          @clear:append="selectedBookId = 0"
          v-model="selectedBookId"
          item-text="name"
          item-value="bookId"
        ></v-select>
        <v-text-field
          v-model="searchText"
          autocomplete="on"
          :label="$t('placeHolder')"
          :placeholder="$t('search')"
          outlined
          v-on:keyup.enter="getSearchResults"
        ></v-text-field>

        <v-checkbox
          v-model="specialSearch"
          :label="$t('customSearch')"
        ></v-checkbox>

        <v-btn @click.prevent="getSearchResults">{{ $t("search") }}</v-btn>
        <hr />
        <p>{{ $t("total") }} : ({{ getTotalsOfArray(searchResults.books) }})</p>

        <v-expansion-panels focusable>
          <v-expansion-panel
            v-for="item in searchResults.books"
            :key="item.book.bookId"
          >
            <v-expansion-panel-header class="text-h5">
              {{ item.book.name }} ({{ getTotalParagraphsOfArray(item.menus) }})
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ol>
                <li v-for="menu in item.menus" :key="menu.menuId">
                  {{ menu.name }} ({{ menu.pargraphs.length }})
                  <ol>
                    <li
                      v-for="parag in menu.pargraphs"
                      :key="parag.paragraphId"
                    >
                      <!-- <router-link
                      :to="{ path: 'register', query: { plan: 'private' } }"
                      >Register</router-link
                    > -->

                      <router-link
                        v-if="parag.hasiyeNo > 0"
                        style="text-decoration: none"
                        :to="{
                          path:
                            '/' +
                            item.book.latinName +
                            '/' +
                            menu.latinName +
                            '/' +
                            menu.menuId +
                            '#fn:' +
                            parag.hasiyeNo,
                          query: { searchtext: searchText },
                        }"
                        target="_blank"
                      >
                        <p>
                          {{ $t("page") }}:{{ parag.pageNo }}
                          {{ $t("paragraph") }}:{{ parag.paragraphNo }}
                        </p>
                        <span
                          style="color: black"
                          v-html="parag.highLightedContent"
                        />
                      </router-link>

                      <router-link
                        v-else
                        style="text-decoration: none"
                        :to="{
                          path:
                            '/' +
                            item.book.latinName +
                            '/' +
                            menu.latinName +
                            '/' +
                            menu.menuId +
                            '#' +
                            parag.pageNo +
                            '_' +
                            parag.paragraphNo,
                          query: { searchtext: searchText },
                        }"
                        target="_blank"
                      >
                        <p>
                          {{ $t("page") }}:{{ parag.pageNo }}
                          {{ $t("paragraph") }}:{{ parag.paragraphNo }}
                        </p>
                        <span
                          style="color: black"
                          v-html="parag.highLightedContent"
                        />
                      </router-link>
                    </li>
                  </ol>
                </li>
              </ol>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </div>
</template>

<script>
import AppApi from "../api/app-api";

export default {
  name: "SearchBook",
  data: () => ({
    searchText: "",
    searchResults: {},
    selectedBookId: 0,
    specialSearch: false,
  }),

  methods: {
    getTotalsOfArray(items) {
      let total = 0;
      if (items && items.length > 0) {
        items.forEach((element) => {
          total += element.totalCount;
        });
      }

      return total;
    },

    getTotalParagraphsOfArray(items) {
      let total = 0;
      if (items && items.length > 0) {
        items.forEach((element) => {
          total += element.pargraphs.length;
        });
      }

      return total;
    },
    getSearchResults() {
      if (this.selectedBookId == null) {
        this.selectedBookId = 0;
      }
      if (this.searchText != "" && this.languageId > 0) {
        console.log("languageId : ", this.languageId);

        AppApi.getSearchResultsByBook(
          this.searchText,
          this.languageId,
          this.selectedBookId,
          this.specialSearch
        )
          .then((res) => {
            if (res.books == null) return (this.searchResults = []);

            res.books.sort((a, b) => {
              return a.book.orderNo > b.book.orderNo ? 1 : -1;
            });
            this.searchResults = res;
            // console.log("res: ", res);
          })
          .catch((exc) => {
            console.log("exc: ", exc);
          });
      } else {
        this.searchResults = [];
      }
    },
  },
  computed: {
    languageCode() {
      const language = AppApi.getCurrentLanguage();
      if (language != null) {
        const code = language.Code;
        if (code != null && code.length > 0) {
          return code;
        }
      }

      return "tr";
    },
    languageId() {
        const language = AppApi.getCurrentLanguage();
        console.log("language computed : ", language);

      if (language != null) {
        const languageId = language.languageId;
        if (languageId != null && languageId > 0) {
          console.log("languageId : ", languageId);
          return languageId;
        }
      }

      return 1;
    },
    books() {
      const books = AppApi.getBooks();
      // console.log("books: ", books);
      // books.push({bookId:0, name:"All Books"});
      return books;
    },
  },
  mounted() {
    if (this.$route.query) {
      this.searchText = this.$route.query.searchtext;
      console.log("route : ", this.$route);
      this.getSearchResults();
    }
  },
};
</script>
