import Vue from "vue";
// import { createApp } from "vue";
import App from "./App.vue";
import ErrorApp from "./ErrorPage.vue";
import vuetify from "@/plugins/vuetify";
import router from "./router";
import store from "./stores";
import AppApi from "./api/app-api";
import AppLayout from "@/layouts/main/index.vue";
import SearchLayout from "@/layouts/search/index.vue";
import ErrorLayout from "@/layouts/error/index.vue";

import i18n from "@/plugins/i18n";

Vue.component("app-layout", AppLayout);
Vue.component("search-layout", SearchLayout);
Vue.component("error-layout", ErrorLayout);

Vue.config.productionTip = false;

import VueGtag from "vue-gtag";
// import VueMeta from "vue-meta";

// Vue.use(VueMeta, {
//   keyName: "metaInfo",
//   attribute: "",
//   tagIDKeyName: "vmid",
//   refreshOnceOnNavigation: true,
// });


function createInstanse() {
  new Vue({
    router,
    vuetify,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount("#app");
}

function createError() {
  new Vue({
    router,
    vuetify,
    i18n,
    // created: function () {
    //   console.log(this);
    // },
    render: (h) => h(ErrorApp),
  }).$mount("#app");
}

let nextId = 1000;
const openMenus: any = [];

AppApi.getSiteSettings()
    .then(() => {

        const siteSettings: any = AppApi.getCurrentSiteSettings();

        if (siteSettings.tenantId == "super-tenant") {
            createError();
            return;
        } else {
            createInstanse();

            let gtagui = "";
            // // gtagui = "UA-123394305-2";

            if (siteSettings != null && siteSettings.googleAnalytic.trim().length === 0) {
                gtagui = "";
            } else {
                gtagui = siteSettings.googleAnalytic;

                Vue.use(
                    VueGtag,
                    {
                        config: { id: gtagui },
                    },
                    router
                );
            }
            console.log("gtag: ", gtagui);
        }
   
    document.title = siteSettings.siteName;
  })
    .then(() => {
        const siteSettings: any = AppApi.getCurrentSiteSettings();

        if (siteSettings.tenantId != "super-tenant") {
            setLanguages();
        }

  })
  .catch((e) => {
    console.log("error : ", e);
    createError();
  });

function setLanguages() {
  AppApi.setLeftMenuState(true);

  AppApi.setLanguages().then(() => {
    const litems = store.state.Litems;
    const rparams = router.currentRoute.params;
    addLanguages(litems[0]);

    AppApi.setLitems(litems);

    openMenus.push(0);

    if (rparams.menuId) {
      const menuId = rparams.menuId;

      AppApi.getLangAndBookByMenuId(menuId).then((res) => {
        const menuSpecs: any = res;
        const curLanguage: any = store.state.Languages.filter((item: any) => {
          return item.languageId == menuSpecs.languageId;
        })[0];

        AppApi.setCurrentLanguage(curLanguage);
        AppApi.isRtl();

        AppApi.SetAllBookReadersByLanguageId(menuSpecs.languageId);

        AppApi.setMenusByLanguageId(menuSpecs.languageId).then((menu) => {
          const curMenu = store.state.LanguageMenus.filter((item: any) => {
            return item.menuId == menuSpecs.menuId;
          })[0] as any;

          AppApi.setCurrentMenu(curMenu);

          // AppApi.SetSoundFilesByMenuId(curMenu.menuId);

          const selectedLanguageMenu: any = litems[0].children.filter(
            (item: any) => {
              return item.menuId == menuSpecs.languageId;
            }
          )[0];

          AppApi.setBooks();

          const books = store.getters.getBooks;

          const curBook = books.filter((item: any) => {
            return item.bookId == menuSpecs.bookId;
          })[0];

          AppApi.setCurrentBook(curBook);

          addBooks(selectedLanguageMenu, menuSpecs.bookId, menuSpecs.menuId);

          AppApi.setLitems(litems);

          const curLanguageMenu: any = litems[0].children.filter(
            (item: any) => {
              return item.menuId == menuSpecs.languageId;
            }
          )[0];

          curLanguageMenu.active = true;

          openMenus.push(curLanguageMenu.id);

          AppApi.setLitems(litems);
        });
      });
    }
    AppApi.setOpenMenus(openMenus);
  });
}

function addLanguages(item: any) {
  if (item.menuType == 0) {
    const langs = store.state.Languages;
    // console.log("langs : " + langs);
    langs.sort((a: any, b: any) => {
      return a.order > b.order ? 1 : -1;
    });

    langs.forEach((element: any) => {
      const menu = {
        id: 0,
        menuId: element.languageId,
        name: element.name,
        isSection: true,
        parentMenuId: null,
        menuType: 1, // language
        active: false,
        children: [],
      };

      addChild(item, menu);
    });
  }
}

function addChild(item: any, child: any) {
  if (!item.children) {
    item["children"] = [];
  }
  const id = nextId++;
  child.id = id;
  item.children.push(child);
}

function addBooks(item: any, bookId: any, menuId: any) {
  if (item.menuType == 1) {
    const books = store.getters.getBooks;

    books.sort((a: any, b: any) => {
      return a.menuOrderNo > b.menuOrderNo ? 1 : -1;
    });

    books.forEach((element: any) => {
      const menu: any = {
        menuId: element.menuId,
        id: 0,
        bookId: element.bookId,
        parentMenuId: item.menuId,
        menuType: 2, // book
        isSection: true,
        name: element.name,
        active: false,
        sanitizedName: element.sanitizedName,
      };

      const subMenus = AppApi.getMenusByMenuId(menu.menuId);
      if (subMenus.length) {
        menu["children"] = [];
      }
      addChild(item, menu);
      if (menu.bookId == bookId) {
        menu.active = true;
        openMenus.push(menu.id);

        AppApi.setOpenMenus(openMenus);

        AppApi.setActiveMenus([menu.id]);
        addBooksMenu(menu, menuId);
      }
    });
  }
}

function addBooksMenu(item: any, menuId: any) {
  const menus = AppApi.getMenusByMenuId(item.menuId);
  menus.sort((a: any, b: any) => {
    return a.menuOrderNo > b.menuOrderNo ? 1 : -1;
  });
  const nonSectionMenus = menus.filter((sd: any) => {
    return sd.isSection == false;
  });

  if (nonSectionMenus.length == 0) {
    menus.forEach((element: any) => {
      const menu: any = {
        id: 0,
        bookId: element.bookId,
        menuId: element.menuId,
        parentMenuId: element.parentMenuId,
        menuType: 3, // book
        isSection: true,
        name: element.name,
        active: false,
        sanitizedName: element.sanitizedName,
      };
      const subMenus = AppApi.getMenusByMenuId(menu.menuId);
      if (subMenus.length) {
        menu["children"] = [];
      }

      addChild(item, menu);
      if (menu.menuId == menuId) {
        menu.active = true;
        openMenus.push(menu.id);
        AppApi.setOpenMenus(openMenus);

        AppApi.setActiveMenus([menu.id]);
      }
    });
  }
}
